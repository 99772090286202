// 👇️ using noConflict mode
$(document).ready(function(){
    $('#carousel-related-product').slick({
    infinite: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 3,
    dots: true,
    responsive: [{
    breakpoint: 1024,
    settings: {
    slidesToShow: 3,
    slidesToScroll: 3
}
},
{
    breakpoint: 600,
    settings: {
    slidesToShow: 2,
    slidesToScroll: 3
}
},
{
    breakpoint: 480,
    settings: {
    slidesToShow: 2,
    slidesToScroll: 3
}
}
    ]
});



        // licznik w koszyku
        const totalQuantity = localStorage.getItem('totalQuantity');
        if (totalQuantity !== null) {
            $('#cart-total-items').text(totalQuantity);
        }

});
